<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 bookings-screen full-width gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          clean
        </div>
        <template v-slot:right></template>
      </top-header-menu-wrapper>
    </template>
    <div class="bookings-body" style="height: auto;">
      <!-- Header -->
      <div class="booking-preset-icon-wrapper"></div>
      <ul class="clebex-item-section pill mt-0 mb-0">
        <li v-if="search" class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper search">
            <search-component
              :ref="`search${bookingsPreset.presetId}`"
              :searchQuery="searchQuery[bookingsPreset.presetId]"
              @inputChanged="inputChanged($event)"
              @lostFocus="lostFocus(bookingsPreset.presetId)"
              @clearSearch="clearSearch()"
            ></search-component>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section pill bookings-header">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper grey-bg">
            <!-- resource -->
            <span @click="sortDeclarations('resourceName')" class="label">
              <span class="highlight">{{
                displayLabelName("clean.clean.resource")
              }}</span>
            </span>
            <!-- location -->
            <span @click="sortDeclarations('id')" class="label">
              <span class="highlight">{{
                displayLabelName("clean.clean.location")
              }}</span>
            </span>
            <!-- start date time -->
            <span @click="sortDeclarations('subject')" class="label">
              <span class="highlight">{{
                displayLabelName("clean.clean.start-date-time")
              }}</span>
            </span>
          </div>
        </li>
      </ul>
      <!-- Items -->
      <ul class="clebex-item-section pill mt-0 mb-0">
        <li
          v-for="(levelResource, i) in levelResources"
          :key="i"
          class="clebex-item-section-item row"
        >
          <div class="clebex-item-content-wrapper">
            <div
              class="booking-item-column"
              @click="openMap(levelResource, 1)"
              :style="[levelResource.map_id ? 'cursor: pointer;' : '']"
            >
              <span class="column-name"
                >{{ displayLabelName("clean.clean.resource") }}:
              </span>
              <span class="highlight">{{ levelResource.resource }}</span>
            </div>
            <div
              class="booking-item-column"
              @click="openMap(levelResource)"
              :style="[levelResource.map_id ? 'cursor: pointer;' : '']"
            >
              <span class="column-name"
                >{{ displayLabelName("clean.clean.location") }}:
              </span>
              <span class="highlight">{{ levelResource.level }}</span>
            </div>
            <div class="booking-item-column">
              <span class="column-name"
                >{{ displayLabelName("clean.clean.start-date-time") }}:
              </span>
              <span class="highlight">{{ levelResource.datetime_start }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <slider-modal
      v-if="showSliderModal && modalData.length"
      :show="showSliderModal"
      :data="modalData"
      confirm-button-label="Close"
      :confirm-action="cancelStatusDialog"
      @close="cancelStatusDialog"
    >
      <template v-slot="{ data }">
        <slider-modal-content :data="data"></slider-modal-content>
      </template>
    </slider-modal>
    <template v-slot:footer>
      <section class="clean-footer-menu icon-foo1">
        <nav class="menu">
          <ul class="menu-items" v-if="showCameraButton">
            <li class="menu-item central-item icon-foo2">
              <qr-code-scanner-button :showCameraButton="showCameraButton" />
            </li>
          </ul>
        </nav>
      </section>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import QrCodeScannerButton from "@/components/global/QrCodeScannerButton";
import { mapState, mapActions } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "Clean",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    QrCodeScannerButton,
    SliderModal: defineAsyncComponent(() =>
      import("@/components/global/SliderModal")
    ),
    SliderModalContent: defineAsyncComponent(() =>
      import("@/components/bookings/SliderModalContent")
    ),
  },
  data() {
    return {
      listView: true,
      showSliderModal: false,
      showCameraButton: true,
    };
  },
  created() {
    this.setInitialValues();
  },
  computed: {
    ...mapState("clean", ["levelResources", "selectedLevel"]),
  },
  methods: {
    ...mapActions("clean", ["getLevelResources", "setSelectedLevel"]),
    openMap(resourceLevel, includeResource = null) {
      if (!includeResource) {
        resourceLevel.resource_id = null;
      }
      this.setSelectedLevel(resourceLevel);
      this.$router.push({ name: "r_clean-resources-map" });
    },
    setInitialValues() {
      this.$store.commit("global/setIsFullWidthScreen", true, {
        root: true,
      });
      this.getLevelResources();
    },
    cancelStatusDialog() {
      this.showSliderModal = false;
    },
    setUsers() {
      let users = [];
      users.push(this.userData.data);
      this.delegatedBy.forEach((element) => {
        if (
          !users.find((el) => el.id === element.delegator.id) &&
          element.permission &&
          element.permission.permission === "MY_BOOKINGS" &&
          this.isValidDateTIme(element.datetime_from, element.datetime_to)
        ) {
          users.push(element.delegator);
        }
      });
      this.$store.commit("bookings/setAvailableHosts", users, {
        root: true,
      });
    },
    isValidDateTIme(from, to) {
      const dateFrom = DateTime.fromISO(from).startOf("day");
      const dateTo = to ? DateTime.fromISO(to).endOf("day") : null;
      const dateNow = DateTime.now();
      if (
        (dateNow > dateFrom && !dateTo) ||
        (dateNow > dateFrom && dateNow < dateTo)
      ) {
        return true;
      }
      return false;
    },
    startDeleteDeclarations() {
      this.deleteDeclarations(this.deleteDeclarationIds);
    },
  },
  watch: {
    presets() {
      this.getBookings({
        resourceIds: null,
        presetId: null,
        extend: null,
      });
    },
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true,
    });
  },
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
